import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export const InnovationsContainer = styled.div`
	${({ theme: { colors, unit, breakpoints } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${unit * 8}px;
		color: ${colors.black};
		max-width: ${unit * 190}px;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			gap: ${unit * 3}px;
		}
	`}
`;

export const Title = styled.h2`
	${({ theme: { breakpoints } }) => css`
		font-size: 48px;
		line-height: 57.7px;
		text-align: center;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			font-size: 40px;
			line-height: 48.08px;
		}
	`}
`;

export const InnovationsList = styled(Swiper)`
	width: 100%;

	.swiper {
		overflow-y: visible !important;
	}

	.swiper-wrapper {
		display: flex;
		position: inherit !important;
	}
`;

export const InnovationsItem = styled(SwiperSlide)`
	${({ theme: { unit, colors } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${unit * 2}px;
		width: fit-content;

		&:hover {
			img {
				transform: scale(115%);
			}

			p {
				border-color: ${colors.beige};
			}
		}
	`}
`;

export const Text = styled.p`
	${({ theme: { colors, unit } }) => css`
		margin: 0;
		font-size: 21px;
		line-height: 25.24px;
		color: ${colors.black};
		border-bottom: 1px solid transparent;
		width: fit-content;
		padding: ${unit * 0.5}px 0;
		transition: 0.3s all ease-in-out;
	`}
`;

export const ImageWrapper = styled.div`
	${({ theme: { unit, breakpoints } }) => css`
		max-width: ${unit * 43.75}px;
		max-height: ${unit * 43.75}px;
		overflow: hidden;

		img {
			display: block;
			width: 100%;
			height: 100%;
			transition: 0.5s;
		}

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			max-width: 100%;
		}
	`}
`;

//Какой-то косяк с рендером карусели, эелементы рендерятся за пределами враппера, прописываем свойство руками
InnovationsItem.displayName = 'SwiperSlide'
