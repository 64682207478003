import styled, { css } from 'styled-components';
import img from '@resources/images/main/background-text.png';

export const DiscoverContainer = styled.div`
	${({ theme: { colors, unit, breakpoints } }) => css`
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: ${colors.warmGray};
		padding: ${unit * 10}px;
		gap: ${unit * 17.375}px;
		background-image: url(${img.src});
		background-origin: border-box;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		width: calc(100% + ${unit * 10}px);

		@media (max-width: ${breakpoints.maxTableWidth}) {
			padding: ${unit * 5}px;
			gap: ${unit * 34.25}px;
		}

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			padding: ${unit * 2.5}px;
			gap: ${unit * 4}px;
			width: calc(100% + ${unit * 5}px);
		}
	`}
`;

export const LogoWrapper = styled.div`
	${({ theme: { unit, breakpoints } }) => css`
		svg {
			max-height: ${unit * 6}px;
			height: 100%;
			width: ${unit * 23.75}px;
		}

		@media (max-width: ${breakpoints.maxTableWidth}) {
			margin-top: ${unit * 5}px;
		}
	`}
`;

export const TextWrapper = styled.div`
	${({ theme: { unit } }) => css`
		display: flex;
		flex-direction: column;
		gap: ${unit * 4}px;
		align-items: center;
		max-width: ${unit * 100}px;
	`}
`;

export const Title = styled.h2`
	${({ theme: { unit, breakpoints } }) => css`
		font-size: 64px;
		line-height: 76.93px;
		text-transform: uppercase;
		text-align: center;
		max-width: ${unit * 95}px;

		@media (max-width: ${breakpoints.maxTableWidth}) {
			font-size: 56px;
			line-height: 67.31px;
		}
	`}
`;

export const Desc = styled.p`
	${({ theme: { breakpoints } }) => css`
		font-family: 'Inter';
		font-size: 22px;
		line-height: 26.63px;
		font-weight: 300;
		text-align: center;
		margin: 0;

		@media (max-width: ${breakpoints.maxTableWidth}) {
			font-size: 16px;
			line-height: 19.36px;
		}
	`}
`;

export const Button = styled.button`
	${({ theme: { colors, unit, breakpoints } }) => css`
		font-family: 'Inter';
		outline: none;
		border: 1px solid ${colors.white};
		background-color: transparent;
		padding: ${unit * 2.375}px ${unit * 3}px;
		font-size: 15px;
		font-weight: 300;
		line-height: 18.15px;
		height: ${unit * 7}px;
		transition: 0.3s all ease-in-out;

		&:hover {
			background-color: ${colors.beige};
			border-color: ${colors.beige};
		}

		@media (max-width: ${breakpoints.maxTableWidth}) {
			margin-bottom: ${unit * 5}px;
		}
	`}
`;