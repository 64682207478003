import React from 'react';
import * as S from './styled';
import { CustomIcon, ImageForSEO } from '@components/ui';
import { useTranslation } from 'next-i18next';
import BackgroundText from '@resources/images/main/background-text.png';

export const Discover = () => {
	const { t } = useTranslation();

	return (
		<S.DiscoverContainer>
			<ImageForSEO src={BackgroundText} alt="Badezimmer | Artiviana 1956" />
			<S.LogoWrapper>
				<CustomIcon fileName="logo" />
			</S.LogoWrapper>
			<S.TextWrapper>
				<S.Title>{t('main.discover.title')}</S.Title>
				<S.Desc>{t('main.discover.text')}</S.Desc>
			</S.TextWrapper>
			<S.Button>{t('main.discover.button')}</S.Button>
		</S.DiscoverContainer>
	);
};
