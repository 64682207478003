import { SeoProps } from "@components/entities/seo/types";
import React, { ReactElement } from "react";

export const buildTags = (config: SeoProps) => {
  const tagsToRender: ReactElement[] = [
    <link
        key="icon-ico"
        rel="icon"
        type="image/x-icon"
        href="./icons/favicon.ico"
    />,
    <link
        key="icon-png"
        rel="icon"
        type="image/png"
        href="./favicons/favicon-32x32.png"
    />
  ];

  if (config.title) {
    tagsToRender.push(<title key="title">{config.title}</title>);
  }

  if (config.description) {
    tagsToRender.push(<meta name="description" content={config.description} key={"description"}/>);
  }

  return tagsToRender;
};
