import React, {FC} from "react";
import {SeoProps} from "@components/entities/seo/types";
import {Helmet} from "react-helmet";
import {buildTags} from "@components/entities/seo/buildTags";

interface ISEO {
    config: SeoProps;
}

const SEO: FC<ISEO> = ({config}) => {
    const {title, description} = config;

    return (
        <Helmet>
            {buildTags({
                title,
                description
            })}
        </Helmet>
    );
};

export default SEO;
