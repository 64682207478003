import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
	${({ theme: { unit, breakpoints } }) => css`
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		gap: ${unit * 10}px;
		padding-bottom: ${unit * 10}px;

		@media (max-width: ${breakpoints.maxTableWidth}) {
			gap: ${unit * 8}px;
			padding-bottom: ${unit * 8}px;
		}

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			gap: ${unit * 5}px;
			padding-bottom: ${unit * 5}px;
		}
	`}
`;
