import Link from 'next/link';
import Image from 'next/image';
import styled, { css } from 'styled-components';

export const ProductContainer = styled.div`
	${({ theme: { breakpoints } }) => css`
		display: flex;

		@media (max-width: ${breakpoints.maxTableWidth}) {
			flex-direction: column;
			width: 100%;
		}
	`}
`;

export const ProductItem = styled(Link)`
	${({ theme: { colors, unit, breakpoints } }) => css`
		background-color: ${colors.white};
		position: relative;
		padding: ${unit * 10}px ${unit * 5}px ${unit * 10}px ${unit * 10}px;
		overflow: hidden;

		&:nth-child(2n) {
			padding: ${unit * 10}px ${unit * 10}px ${unit * 10}px ${unit * 5}px;
		}

		&:hover {
			background-color: ${colors.beige};
		}

		@media (max-width: ${breakpoints.smDesktopWidth}) {
			padding: ${unit * 5}px ${unit * 2.5}px ${unit * 5}px ${unit * 5}px;

			&:nth-child(2n) {
				padding: ${unit * 5}px ${unit * 5}px ${unit * 5}px ${unit * 2.5}px;
			}
		}

		@media (max-width: ${breakpoints.maxTableWidth}) {
			padding: ${unit * 10}px ${unit * 5}px ${unit * 5}px ${unit * 5}px;

			&:nth-child(2n) {
				padding: ${unit * 5}px ${unit * 5}px ${unit * 10}px ${unit * 5}px;
			}
		}

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			padding: ${unit * 5}px ${unit * 2.5}px ${unit * 2.5}px ${unit * 2.5}px;

			&:nth-child(2n) {
				padding: ${unit * 2.5}px ${unit * 2.5}px ${unit * 5}px ${unit * 2.5}px;
			}
		}
	`}
`;

export const ImageWrapper = styled.div`
	${({ theme: { unit, breakpoints } }) => css`
		max-width: ${unit * 85}px;
		max-height: ${unit * 92.5}px;
		overflow: hidden;
		position: relative;

		img {
			display: block;
			width: 100%;
			height: 100%;
			transition: 0.5s;

			&:hover {
				transform: scale(105%);
			}
		}

		@media (max-width: ${breakpoints.maxTableWidth}) {
			max-width: 100%;
		}
	`}
`;

export const Text = styled.h2<{ $isBlack?: boolean }>`
	${({ theme: { unit, colors, breakpoints }, $isBlack }) => css`
		font-size: 40px;
		line-height: 48.08px;
		position: absolute;
		bottom: ${unit * 5}px;
		color: ${$isBlack ? colors.black : colors.white};
		text-align: center;
		width: 100%;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			font-size: 24px;
			line-height: 28.85px;
		}
	`}
`;

export const FloatingText = styled(Image)`
	${({ theme: { unit, breakpoints } }) => css`
		position: absolute;

		@media (max-width: ${breakpoints.maxMobileWidth}) {
			width: ${unit * 17.5}px;
		}
	`}
`;
