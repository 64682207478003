import React from 'react';
import * as S from './styled';
import { CustomIcon } from '@components/ui';
import { useTranslation } from 'next-i18next';

export const Banner = () => {
	const { t } = useTranslation();
	const scrollToAnchor = (anchor: string) => {
		const anchorElement = document.getElementById(anchor);
		if (anchorElement) {
			anchorElement.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return (
		<S.BannerContainer>
			<S.Title>{t('main.title')}</S.Title>
			<S.ExploreWrapper onClick={() => scrollToAnchor('products')}>
				<S.Text>{t('main.button')}</S.Text>
				<CustomIcon fileName="icon-arrow" />
			</S.ExploreWrapper>
		</S.BannerContainer>
	);
};
