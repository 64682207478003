import React  from 'react';
import * as S from './styled';
import { Navigation } from 'swiper/modules';
import { CustomIcon } from '@components/ui';
import { useTranslation } from 'next-i18next';
import { observer } from 'mobx-react-lite';
import { useStore } from '@core/hooks/use-store';
import { hasData } from '@remote';

export const Collections = observer(() => {
	const { collections } = useStore('core');

	const { t } = useTranslation();

	return (
		<S.CollectionsContainer>
			<S.Title>{t('main.collections.title')}</S.Title>
			<S.CollectionsList
				slidesPerView="auto"
				navigation
				modules={[Navigation]}
				breakpoints={{
					0: {
						spaceBetween: 0,
					},
					768: {
						spaceBetween: 40,
					},
				}}

			>
				{hasData(collections) &&
					collections.data.map((item) => (
						<S.CollectionsItem key={item.id} $background={item.image.desktop.jpg_double}>
							<S.Image image={item.image} alt={`${item.title} | Artiviana 1956`} />
							<S.TextWrapper href={`/collections/${item.id}`}>
								<S.ItemTitle>{item.title}</S.ItemTitle>
								<S.DescWrapper>
									<S.ItemDesc>{t('main.collections.buttonRange')}</S.ItemDesc>
									<CustomIcon fileName="icon-arrow" />
								</S.DescWrapper>
							</S.TextWrapper>
						</S.CollectionsItem>
					))}
			</S.CollectionsList>
			<S.Button href="/collections">{t('main.collections.buttonAll')}</S.Button>
		</S.CollectionsContainer>
	);
});
