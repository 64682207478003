import React, {useMemo} from 'react';
import * as S from './styled';
import MixersText from '@resources/images/main/text-1.png';
import ShowersText from '@resources/images/main/text-2.png';
import Mixers from '@resources/images/main/products/func.png';
import Showers from '@resources/images/main/products/showers.jpg';
import {useTranslation} from 'next-i18next';
import {useStore} from "@core/hooks/use-store";
import {hasData} from "@remote";
import {observer} from "mobx-react-lite";
import Image from "next/image";

const Products = () => {
    const {t} = useTranslation();
    const {categories} = useStore("core");

    const linkGenerator = useMemo(() => {
        return (isMixer: boolean) => {
            if (!hasData(categories)) return ""
            const id = categories.data.find(cat => cat.title.en === (isMixer ? "Bathroom mixers" : "Showers"))!.id
            return `/products/${id}?page_size=12&page_number=1`
        }
    }, [categories]);

    return (
        <S.ProductContainer id="products">
            <S.ProductItem href={hasData(categories) ? linkGenerator(true) : ""}>
                <S.ImageWrapper>
                    <Image src={Mixers} alt="Badezimmer | Artiviana 1956"/>
                    <S.Text $isBlack>{t('main.products.mixers')}</S.Text>
                </S.ImageWrapper>
                <S.FloatingText style={{top: 0, left: 0}} src={MixersText} alt="Badezimmer | Artiviana 1956"/>
            </S.ProductItem>
            <S.ProductItem href={hasData(categories) ? linkGenerator(false) : ""}>
                <S.ImageWrapper>
                    <Image src={Showers} alt="Badezimmer | Artiviana 1956"/>
                    <S.Text>{t('main.products.showers')}</S.Text>
                </S.ImageWrapper>
                <S.FloatingText style={{bottom: 0, right: 0}} src={ShowersText} alt="Badezimmer | Artiviana 1956"/>
            </S.ProductItem>
        </S.ProductContainer>
    );
};

export default observer(Products)
