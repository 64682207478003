import React from 'react';
import * as S from './styled';
import TouchReel from '@resources/images/main/innovations/TouchReel.jpg';
import XTap from '@resources/images/main/innovations/X-Tap.jpg';
import Shower from '@resources/images/main/innovations/Shower.jpg';
import MultiDock from '@resources/images/main/innovations/MultiDock.jpg';
import { useTranslation } from 'next-i18next';
import Image from "next/image";

export const Innovations = () => {
	const { t } = useTranslation();

	return (
		<S.InnovationsContainer>
			<S.Title>{t('main.innovations.title')}</S.Title>
			<S.InnovationsList
				slidesPerView="auto"
				breakpoints={{
					0: {
						spaceBetween: 20,
						centeredSlides: true,
						centeredSlidesBounds: true,
					},
					768: {
						spaceBetween: 40,
					},
				}}
			>
				<S.InnovationsItem>
					<S.ImageWrapper>
						<Image src={TouchReel} alt="Badezimmer | Artiviana 1956" />
					</S.ImageWrapper>
					<S.Text>TouchReel</S.Text>
				</S.InnovationsItem>
				<S.InnovationsItem>
					<S.ImageWrapper>
						<Image src={XTap} alt="Badezimmer | Artiviana 1956" />
					</S.ImageWrapper>
					<S.Text>X-Tap</S.Text>
				</S.InnovationsItem>
				<S.InnovationsItem>
					<S.ImageWrapper>
						<Image src={Shower} alt="Badezimmer | Artiviana 1956" />
					</S.ImageWrapper>
					<S.Text>Shower Touch3 Cartridge</S.Text>
				</S.InnovationsItem>
				<S.InnovationsItem>
					<S.ImageWrapper>
						<Image src={MultiDock} alt="Badezimmer | Artiviana 1956" />
					</S.ImageWrapper>
					<S.Text>MultiDock™</S.Text>
				</S.InnovationsItem>
			</S.InnovationsList>
		</S.InnovationsContainer>
	);
};
